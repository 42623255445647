@import '../../styles/variables';

.age-verification {
  z-index: 999;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
  background: RGB(0 0 0 / 80%);
  overflow-y: hidden;
  padding: 0 calc(var(--spacing-unit) * 2);
  .age-container {
    overflow: hidden;
    z-index: 9;
    position: relative;
    border-radius: var(--spacing-unit);
    background-color: var(--white);
    padding: calc(var(--spacing-unit) * 2) var(--spacing-unit);
    .age-text {
      padding: 0 calc(var(--spacing-unit) * 3) 0 calc(var(--spacing-unit) * 3);
      margin-bottom: var(--spacing-unit);
      @include media-breakpoint-up(md) {
        padding: 0 calc(var(--spacing-unit) * 5) 0 calc(var(--spacing-unit) * 5);
      }
    }
    > svg {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      z-index: -1;
    }
    img {
      z-index: -1;
    }
    .age-title {
      color: var(--primary);
      @include media-breakpoint-up(md) {
        padding: 0 20%;
      }
    }
    .age-smalltext {
      color: var(--primary);
    }
    .cta-button {
      margin-bottom: var(--spacing-unit);
    }
    .age-buttons {
      padding: 0;
      & > section {
        width: auto !important;
      }
    }
    .under-age {
      text-decoration: underline;
      @include media-breakpoint-up(md) {
        display: block;
      }
    }
    .state-container {
      .state-button {
        border: var(--primary);
        height: 60px;
        border-radius: 8px;
        padding: 5px 60px;
        &:hover {
          opacity: 0.5;
          cursor: pointer;
        }
      }
      @include media-breakpoint-down(sm) {
        margin: unset;
      }
    }
    .select-wrapper {
      flex-direction: column;
      label {
        margin-bottom: 10px;
        color: var(--primary);
      }
    }
    &[data-browser-version='true'] {
      overflow: unset;
      .age-buttons {
        position: relative;
      }
      .dropdown-container {
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        border: 1px solid var(--input-normal);
        border-radius: 6px;
        .dropdown {
          display: inline-flex;
          cursor: pointer;
          font-family: var(--font);
          font-size: 16px;
          line-height: 22px;
          .p-dropdown-label {
            padding: 12px;
          }
          .p-dropdown-trigger {
            display: flex;
            align-items: center;
            width: 30px;
            .p-dropdown-trigger-icon {
              --transform-scale-x: -1;
            }
          }
        }
      }
      .dropdown-list-container {
        position: absolute;
        top: 48px;
        .p-dropdown-panel {
          z-index: 20;
          background-color: var(--white);
          border: 1px solid #d1d5db;
          border-radius: 6px;
          padding-top: 0.75rem;
          max-height: 225px;
          overflow: auto;
          li {
            list-style-type: none;
            padding: 0.75rem 1.25rem;
          }
        }
      }
    }
  }
}
