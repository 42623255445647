@import '../../styles/variables';

.cart-summary {
  gap: 24px;
  .summary-container {
    gap: 24px;
    .tiles {
      gap: var(--spacing-unit);
      flex-grow: 1;
    }
    .loyalty-points {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      .label {
        padding-top: 2px;
      }
    }
    .order-summary-wrapper {
      width: 330px;
      .order-summary {
        flex-grow: 1;
        border-radius: 16px;
        &[data-logged-in='true'] {
          border-top-right-radius: 0;
          border-top-left-radius: 0;
        }
        .hairline {
          border-top: 1px solid var(--secondary-dark);
        }
      }
      .guest-checkout-link {
        &[data-disabled='true'] {
          color: var(--disabled);
          cursor: default;
          pointer-events: none;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    gap: 16px;
    .summary-container {
      flex-direction: column;
      gap: 16px;
      .order-summary-wrapper {
        width: 100%;
      }
    }
  }
}
